import React, { useMemo, useRef, useState } from 'react';
import { Button } from 'antd';
import CustomModal from '../../../../../../shared/custom-modal/custom-modal';
import APNSelection from '../../apn-selection/apn-selection';
import DestTypeGroup from '../dest-type-group';
import { ReactComponent as NoApnDoc } from '../../../../../../../assets/svg/doc.svg';

import './select-destination-modal.scss';
import DestinationTree from '../destination-tree/destination-tree';
import { CheckedNode } from '../../../../../../../dtos/traffic-dest.dto';
import { useStores } from '../../../../../../store';
import { observer } from 'mobx-react-lite';

export interface SelectDestinationModalProps {
    mode: 'add' | 'edit';
    apnName? : string;
    closeModal?: () => void;
    activeType?: string;
    preSelectedPercent?: number;
}
export const SelectDestinationModal = observer((props: SelectDestinationModalProps) => {
    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [destinationType, setDestinationType] = useState<'white' | 'black'>('white');
    const [checkedDestinations, setCheckedDestinations] = useState<string[]>([]);
    const destinationTreeRef = useRef<{ getFinalCheckedDestinations: () => CheckedNode[] }>(null);

    const isAddMode = props.mode === 'add';

    const apnName = useMemo(() => {
        if (isAddMode) {
            return selectedValues.join();
        } else {
            return props.apnName;
        }
    }, [selectedValues, isAddMode, props.apnName]);

    const destinationExistingList = useMemo(() => {
        if (isAddMode) {
            return [];
        } else {
            return useCaseStore.destValueByType(props.activeType, apnName);
        }
    }, [destinationType, isAddMode, apnName, useCaseStore.apnPreferences]);

    const handleOk = () => {
        if (destinationTreeRef.current) {
            const finalCheckedDestinations = destinationTreeRef.current.getFinalCheckedDestinations();
            if (isAddMode) {
                useCaseStore.addApnList([apnName]);
                useCaseStore.initDestValues(apnName, [{ listType: destinationType, parameterValues: finalCheckedDestinations }]);
            } else {
                const combinedList = destinationExistingList.concat(finalCheckedDestinations);
                const uniqueCombinedList = Array.from(
                    combinedList.reduce((map, item) => map.set(item.value, item), new Map()).values()
                );
                useCaseStore.changeParamValueByApn(apnName, `${props.activeType}_list_destinations`, uniqueCombinedList);
            }

        }
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        if (props.closeModal) {
            props.closeModal();
        }
    };

    return (
        <>
            <Button className={'secondary-btn'}
                    type="primary"
                    onClick={() => setIsModalOpen(true)}>
                { isAddMode ? 'Select Destinations' : '+ Destinations'}
            </Button>
            {isModalOpen && <CustomModal
                isOpen={isModalOpen}
                width={750}
                title={`${props.mode} Destinations`}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={isAddMode ? 'Add' : 'Save changes'}
                customClassName="select-dest-modal"
                okButtonProps={{ disabled: !checkedDestinations.length}}
                cancelButtonProps={{ className: 'secondary-btn' }}>
                <div className="select-dest-modal">
                    <div className="select-dest-modal-header">
                        {isAddMode ?
                            <>
                                <APNSelection selectedValues={selectedValues} setSelectedValues={setSelectedValues}/>
                                <DestTypeGroup destinationType={destinationType} setDestinationType={setDestinationType}/>
                            </>
                        : <div className="apn-name">{apnName}</div>
                        }
                    </div>
                    <div className="select-dest-modal-content">
                        {apnName ? <DestinationTree
                                ref={destinationTreeRef}
                                apn={apnName}
                                defaultCheckedDestinations={destinationExistingList.map(dest => dest.value)}
                                preSelectedPercent={props.preSelectedPercent}
                                onCheckedUpdate={setCheckedDestinations}/>:
                            <div className="no-apn-selected">
                                <NoApnDoc />
                                <div className="no-apn-text">
                                    Begin by selecting your preferred APN from the dropdown, then choose your desired destinations, such as URLs or IP addresses.
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </CustomModal>}
        </>
    );
});
