import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import LoaderComponent from '../../../../../../shared/loader/loader-component';
import useCaseService from '../../../../../../../services/use-case.service';
import { useStores } from '../../../../../../store';
import { CheckedNode, TreeNode } from '../../../../../../../dtos/traffic-dest.dto';
import { buildTreeData, getFinalCheckedNodes, isIPInSubnet } from './dest-tree-data.service';
import { ReactComponent as CheckedIcon } from '../../../../../../../assets/svg/checkbox-checked.svg';
import { ReactComponent as Arrow } from '../../../../../../../assets/svg/chevron-left.svg';
import './destination-tree.scss';
import moment from 'moment/moment';
import { convertMBUnitsToString } from '../../../../../../../utils/util';
import { Tooltip } from 'antd';

interface DestinationTreeProps {
    apn: string;
    defaultCheckedDestinations?: string[];
    onCheckedUpdate: (checkedDestinations: string[]) => void;
    preSelectedPercent?: number;
}

export interface DestinationTreeHandle {
    getFinalCheckedDestinations: () => CheckedNode[];
}

const renderIPList = (ips: string[], matchingIPs: string[]): ReactNode[] => {
    return ips.map((ip, index) => (
        <span key={ip} className={matchingIPs.includes(ip) ? 'bold-ip' : ''}>
            {ip}
            {index < ips.length - 1 && ', '}
        </span>
    ));
};

const DestinationTree = forwardRef<DestinationTreeHandle, DestinationTreeProps>(({ apn, defaultCheckedDestinations = [], onCheckedUpdate, preSelectedPercent }, ref) => {
    const { customerStore, useCaseStore } = useStores();
    const [isLoading, setIsLoading] = useState(true);
    const [treeData, setTreeData] = useState<TreeNode[]>([]);
    const [checkedDestinations, setCheckedDestinations] = useState<string[]>(defaultCheckedDestinations);
    const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
    const [filter, setFilter] = useState<string>('');

    const isKeyIncludedInDefaultChecked = (key: string) => {
        return defaultCheckedDestinations.some(dest => {
            if (dest.includes('/') && !key.includes('*')) {
                return isIPInSubnet(key, dest);
            }
            return dest === key;
        });
    };

    useEffect(() => {
        setCheckedDestinations(defaultCheckedDestinations);
        setExpandedNodes([]);
        setIsLoading(true);
        const fromDate = moment().utc().subtract(useCaseStore.trafficDestOffsetDays, 'days').valueOf();
        const toDate = moment().utc().valueOf();
        useCaseService.getTrafficDests(customerStore.selectedCustomer.name, apn, fromDate, toDate).then(data => {
            setTreeData(buildTreeData(data));
            setIsLoading(false);
        });
    }, [apn]);

    useImperativeHandle(ref, () => ({
        getFinalCheckedDestinations: () => {
            return getFinalCheckedNodes(treeData, checkedDestinations);
        }
    }));

    const handleCheck = (key: string, isChecked: boolean) => {
        setCheckedDestinations(prevChecked => {
            const newChecked = isChecked
                ? [...prevChecked, key]
                : prevChecked.filter(k => k !== key);
            onCheckedUpdate(newChecked.filter(k => !isKeyIncludedInDefaultChecked(k)));
            return newChecked;
        });
    };


    const updateChildKeys = (children: TreeNode[], add: boolean, newChecked: string[]) => {
        children.forEach(child => {
            if (add) {
                newChecked.push(child.key);
            } else {
                newChecked = newChecked.filter(k => k !== child.key);
            }
            if (child.children) {
                newChecked = updateChildKeys(child.children, add, newChecked);
            }
        });
        return newChecked;
    };

    const handleParentCheck = (node: TreeNode, isChecked: boolean) => {
        setCheckedDestinations(prevChecked => {
            let newChecked = isChecked
                ? [...prevChecked, node.key]
                : prevChecked.filter(k => k !== node.key);

            if (node.children) {
                newChecked = updateChildKeys(node.children, isChecked, newChecked);
            }

            onCheckedUpdate(newChecked.filter(k => !isKeyIncludedInDefaultChecked(k)));
            return newChecked;
        });
    };

    const filterTreeNodes = (nodes: TreeNode[], filter: string): { filteredNodes: TreeNode[], expandedKeys: string[] } => {
        let expandedKeys: string[] = [];
        const filteredNodes = nodes.reduce<TreeNode[]>((filtered, node) => {
            const matchesFilter = node.key.toLowerCase().includes(filter.toLowerCase());
            const filteredChildren = node.children ? filterTreeNodes(node.children, filter) : { filteredNodes: [], expandedKeys: [] };

            if (matchesFilter || filteredChildren.filteredNodes.length > 0) {
                if (filteredChildren.filteredNodes.length > 0) {
                    expandedKeys.push(node.key);
                }
                filtered.push({
                    ...node,
                    children: filteredChildren.filteredNodes.length > 0 ? filteredChildren.filteredNodes : node.children,
                });
                expandedKeys = [...expandedKeys, ...filteredChildren.expandedKeys];
            }
            return filtered;
        }, []);
        return { filteredNodes, expandedKeys };
    };

    const isIncludeIP = (node: TreeNode): string[] => {
        if (node.type === 'URL' && node.trafficDest?.ips) {
            return node.trafficDest.ips.filter(ip => isKeyIncludedInDefaultChecked(ip));
        }
        return [];
    };

    const renderTreeNodes = (nodes: TreeNode[], level: number = 0, parentChecked: boolean = false) => {
        return nodes
            .map(node => {
                const isChecked = checkedDestinations.includes(node.key);
                const matchingIPs = isIncludeIP(node);
                const isKeyInclude = isKeyIncludedInDefaultChecked(node.key);
                const isDisabled = isKeyInclude || parentChecked || matchingIPs.length > 0;

                const ipList = node.type === 'URL' && node.trafficDest ? node.trafficDest.ips : [];
                const renderedIPList = renderIPList(ipList, matchingIPs);

                return (
                    <div key={node.key} className={`tree-node level-${level}`}>
                        <div className="tree-node-content">
                            <input
                                type="checkbox"
                                checked={isChecked}
                                disabled={isDisabled}
                                className={`custom-checkbox ${isChecked ? 'checked' : ''}`}
                                onChange={() => node.children ? handleParentCheck(node, !isChecked) : handleCheck(node.key, !isChecked)}
                            />
                            <span className={`node-title ${isKeyInclude ? 'key-include' : ''}`}>
                                <Tooltip overlayClassName="tooltip-node-title" title={<span>{node.key}</span>}>
                                    {node.key}
                                </Tooltip>
                            </span>
                            <span className="node-ips-wrapper">
                                {ipList.length > 0 && (
                                    <Tooltip overlayClassName="tooltip-node-ips" title={<span>{renderedIPList}</span>}>
                                        <span className="node-ips">
                                            {renderedIPList}
                                        </span>
                                    </Tooltip>
                                )}
                            </span>
                            <span className="node-traffic">{convertMBUnitsToString(node.totalMb)}</span>
                            <span className="node-percentage">{node.percentage.toFixed(2)}%</span>
                            {node.children && (
                                <button className={`arrow ${expandedNodes.includes(node.key) ? 'expanded' : 'collapse'}`}
                                        onClick={() => toggleExpand(node.key)}>
                                    <Arrow />
                                </button>
                            )}
                        </div>
                        {node.children && expandedNodes.includes(node.key) && (
                            <div className="tree-children">
                                {renderTreeNodes(node.children, level + 1, isChecked)}
                            </div>
                        )}
                    </div>
                );
            });
    };

    const calculateTotalCheckedPercentage = () => {
        const calculateSumPercentage = (nodes: TreeNode[]): number => {
            return nodes.reduce((sum, node) => {
                if (checkedDestinations.includes(node.key) && !isKeyIncludedInDefaultChecked(node.key)) {
                    return sum + node.percentage;
                }
                if (node.children) {
                    return sum + calculateSumPercentage(node.children);
                }
                return sum;
            }, 0);
        };
        return calculateSumPercentage(treeData);
    };

    const toggleExpand = (key: string) => {
        setExpandedNodes(prev => {
            if (prev.includes(key)) {
                return prev.filter(k => k !== key);
            } else {
                return [...prev, key];
            }
        });
    };

    const handleSelectAll = (isChecked: boolean) => {
        const allKeys = (nodes: TreeNode[], parentChecked: boolean = false): string[] => {
            return nodes.reduce((keys, node) => {
                const isDisabled = isKeyIncludedInDefaultChecked(node.key) || parentChecked;
                if (!isDisabled) {
                    keys.push(node.key);
                }
                if (node.children) {
                    keys = keys.concat(allKeys(node.children, isDisabled));
                }
                return keys;
            }, []);
        };

        if (isChecked) {
            const keys = allKeys(treeData);
            setCheckedDestinations(keys);
            onCheckedUpdate(keys.filter(k => !isKeyIncludedInDefaultChecked(k)));
        } else {
            setCheckedDestinations([]);
            onCheckedUpdate([]);
        }
    };

    const areAllNodesChecked = (nodes: TreeNode[], parentChecked: boolean = false): boolean => {
        return nodes.length && nodes.every(node => {
            const isDisabled = isKeyIncludedInDefaultChecked(node.key) || parentChecked;
            return isDisabled || (checkedDestinations.includes(node.key) && (!node.children || areAllNodesChecked(node.children, true)));
        });
    };

    const isAllChecked = useMemo(() => areAllNodesChecked(treeData), [treeData, checkedDestinations, defaultCheckedDestinations]);

    const { filteredNodes, expandedKeys } = useMemo(() => filterTreeNodes(treeData, filter), [treeData, filter]);

    useEffect(() => {
        if (filter) {
            setExpandedNodes(expandedKeys);
        } else {
            setExpandedNodes([]);
        }
    }, [filter, expandedKeys]);

    return (
        <div className="destination-tree">
            {isLoading ? (
                <div className="overlay"><LoaderComponent /></div>
            ) : (
                <div className="destination-tree-content">
                    <div className="tree-header">
                        <div className="tree-header-row">
                            <span className="node-title">Destination</span>
                            <span className="node-ips-wrapper">IP</span>
                            <span className="node-traffic">Traffic Vol.</span>
                            <span className="node-percentage">Traffic %</span>
                        </div>
                        <div className="tree-header-row">
                            <input
                                type="checkbox"
                                className={`custom-checkbox ${isAllChecked ? 'checked' : ''}`}
                                checked={isAllChecked}
                                onChange={() => handleSelectAll(!isAllChecked)}
                            />

                            <input
                                type="text"
                                placeholder="Filter ..."
                                value={filter}
                                onChange={e => setFilter(e.target.value)}
                                className="filter-input"
                            />
                        </div>
                    </div>
                    <div className="tree-content">
                        {filteredNodes.length ? renderTreeNodes(filteredNodes) :
                            <div className="no-result">No Data</div>}
                    </div>
                    <div className="tree-footer">
                        {preSelectedPercent !== undefined && preSelectedPercent !== null && <div className="percentage-display">
                            Preselected <span>{preSelectedPercent.toFixed(2)}%</span>
                        </div>}
                        <div className="total-checked-mb">
                            <CheckedIcon />
                            <div>
                                Total Traffic <span>{calculateTotalCheckedPercentage().toFixed(2)}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

export default DestinationTree;